
import Vue from 'vue'
import moment from 'moment'
import SERVICES_CONSTANTS, { StepByStepGlossary } from '@/constants/services'
import YubikeyAuthMixin from '@/mixins/YubikeyAuthMixin'

enum AuthMethods {
  sms = 'sms',
  ga = 'ga',
  yubikey = 'yubikey'
}

enum ErrorStatuses {
  tokenIsDead = 404,
  invalidPayloadData = 400,
  wrongService = 418
}

export default Vue.extend({
  name: 'KYCSignInSecondFactor',
  mixins: [YubikeyAuthMixin],
  data () {
    return {
      isKycZero: false,
      sessionId: '',
      letRetry: false,
      yubikeyErrors: [],
      smsForm: {
        fields: {
          smsCode: {
            valid: false,
            codeIsSent: false,
            codeSentBefore: false,
            retryTimeout: 60,
            retries: 3,
            letRetry: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES_CONSTANTS.CODE_LENGTH || this.$t('common.errors.codeMustConsist', { length: SERVICES_CONSTANTS.CODE_LENGTH })
            ],
            errorsBucket: []
          }
        }
      },
      gaForm: {
        fields: {
          GACode: {
            valid: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES_CONSTANTS.CODE_LENGTH || this.$t('common.errors.codeMustConsist', { length: SERVICES_CONSTANTS.CODE_LENGTH })
            ],
            errorsBucket: []
          }
        }
      },
      isLoading: false
    }
  },
  computed: {
    getAuthMethod (): string {
      const vm = this as any
      const hasMethod = Object.values(AuthMethods).includes(vm.$route.params.method)
      return hasMethod ? vm.$route.params.method : AuthMethods.sms
    },
    servicesConst () {
      return SERVICES_CONSTANTS
    }
  },
  watch: {
    'smsForm.fields.smsCode.retryTimeout' (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.smsForm.fields.smsCode.retryTimeout = v - 1
        }, 1000)
      } else vm.smsForm.fields.smsCode.letRetry = true
    },
    'smsForm.fields.smsCode.valid' (v) {
      if (v && this.smsForm.fields.smsCode.value.length > 0) this.handleSubmit()
    },
    'gaForm.fields.GACode.valid' (v) {
      if (v && this.gaForm.fields.GACode.value.length > 0) this.handleSubmit()
    },
    'smsForm.fields.smsCode.value' () {
      const vm = this as any
      vm.smsForm.fields.smsCode.errorsBucket = []
    },
    'gaForm.fields.GACode.value' () {
      const vm = this as any
      vm.gaForm.fields.GACode.errorsBucket = []
    }
  },
  methods: {
    yubiKeyAuthInit () {
      const vm = this as any
      vm.yubikeyErrors = []
      vm.YubikeyAuth(StepByStepGlossary.login, null, async (authResponse: any, sessionId: string) => {
        if (!authResponse.success) return vm.setServerError(authResponse.data.errors.join(''))
        const loginResponse = await vm.$services.authorization.signin.doSignInKYC({
          session_id: sessionId
        })
        if (loginResponse.success) {
          vm.handleSuccessLogin(loginResponse)
          return
        }
        if (loginResponse.status === ErrorStatuses.tokenIsDead) {
          vm.$router.replace({ name: 'SignInKYC' })
        } else if (loginResponse.status === ErrorStatuses.wrongService) {
          vm.$router.replace({ name: 'SignInKYC', query: { errors: 'wrongService' } })
        }

        vm.setServerError(loginResponse.data.errors)
      }, this.sessionId, vm.yubiKeyAuthInit)
    },
    setServerError (errors: any) {
      const vm = this as any
      vm.yubikeyErrors.push(errors)
      vm.letRetry = true
    },
    startTimer (): void {
      const vm = this as any
      vm.letRetry = false
      setTimeout(() => {
        vm.letRetry = true
      }, 30000)
    },
    async sendSMS (): Promise<void> {
      const vm = this as any
      vm.smsForm.fields.smsCode.loading = true
      vm.smsForm.fields.smsCode.codeSentBefore = false
      const response = await vm.$services.authorization.signin.sendSMSToLogin({ sessionId: this.sessionId })
      if (response.success) {
        vm.smsForm.fields.smsCode.loading = false
        vm.smsForm.fields.smsCode.codeIsSent = true
        vm.smsForm.fields.smsCode.letRetry = false
        vm.smsForm.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
        vm.smsForm.fields.smsCode.retries -= 1
        setTimeout(() => {
          vm.smsForm.fields.smsCode.retryTimeout -= 1
        }, 1000)
      } else {
        vm.smsForm.fields.smsCode.loading = false
        if (response?.data?.work_until) {
          vm.smsForm.fields.smsCode.codeSentBefore = true
          vm.smsForm.fields.smsCode.codeIsSent = true
          vm.smsForm.fields.smsCode.letRetry = false
          vm.smsForm.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
          vm.smsForm.fields.smsCode.retries -= 1
          setTimeout(() => {
            vm.smsForm.fields.smsCode.retryTimeout -= 1
          }, 1000)
        } else {
          vm.smsForm.fields.smsCode.codeSendError = true
          vm.smsForm.fields.smsCode.letRetry = true
        }
      }
    },
    handleSuccessLogin (response: any): void {
      if (response.status === 200) {
        this.$router.push({ name: 'whitelistIDUserAccount' })
      } else if (response.status === 210) {
        const vm = this as any
        this.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: false,
          title: 'Не удалось перенести КУС',
          content: 'На указанном аккаунте test@test.com отсутствует завершённая верификация KYC. В связи с этим необходимо пройти процедуру проверки KYC.\n' +
            'Вы можете указать другой аккаунт, на котором пройдена верификация KYC. \n',
          actions: [
            {
              title: 'К верификации',
              isAccent: true,
              callback () {
                vm.$store.commit('popupModule/setClosed')
                vm.$router.replace({ name: 'whitelistIDUserAccount' })
              }
            },
            {
              title: 'Другой аккаунт',
              isAccent: false,
              callback () {
                vm.$store.commit('popupModule/setClosed')
                vm.$router.replace({ name: 'SignInKYC' })
              }
            }
          ]
        })
      } else {
        this.$store.dispatch('alertsBus/openAlert', {
          title: 'Ошибка',
          type: 'error',
          autoDelete: true,
          content: 'Не удалось перенести аккаунт'
        })
      }
    },
    async handleSubmit (): Promise<void> {
      const vm = this as any
      vm.isLoading = true

      vm.smsForm.fields.smsCode.errorsBucket = []
      vm.gaForm.fields.GACode.errorsBucket = []
      const method = vm.$route.params.method

      const payload: { sessionId: string, codeSms?: string, codeGa?: string } = {
        sessionId: this.sessionId
      }
      if (method === 'sms') {
        payload.codeSms = vm.smsForm.fields.smsCode.value
      } else payload.codeGa = vm.gaForm.fields.GACode.value

      const validateSessionResponse = await vm.$services.authorization.user.validateSession({
        sessionId: payload.sessionId,
        payload: {
          sms: payload.codeSms,
          ga: payload.codeGa
        }
      })
      if (validateSessionResponse.status === ErrorStatuses.invalidPayloadData) {
        if (method === 'sms') {
          vm.smsForm.fields.smsCode.errorsBucket = validateSessionResponse.data.errors
        } else {
          vm.gaForm.fields.GACode.errorsBucket = validateSessionResponse.data.errors
        }
      }

      const response = await vm.$services.authorization.signin.doSignInKYC({
        session_id: payload.sessionId
      })
      if (response.success) {
        vm.handleSuccessLogin(response)
      } else {
        if (response.status === ErrorStatuses.tokenIsDead) {
          vm.$router.replace({ name: 'SignInKYC' })
        } else if (response.status === ErrorStatuses.invalidPayloadData) {
          if (method === 'sms') {
            vm.smsForm.fields.smsCode.errorsBucket = response.data.errors
          } else {
            vm.gaForm.fields.GACode.errorsBucket = response.data.errors
          }
        } else if (response.status === ErrorStatuses.wrongService) {
          vm.$router.replace({ name: 'SignInKYC', query: { errors: 'wrongService' } })
        }
      }
      vm.isLoading = false
    }
  },
  async created (): Promise<void> {
    this.sessionId = this.$route.query.an3K8QkkJb3Mzz ?? ''
    const method = this.$route.params.method
    if (method === AuthMethods.sms) this.sendSMS()
  },
  mounted (): void {
    const vm = this as any
    const isYubiKeyAuth = vm.getAuthMethod === 'yubikey'
    if (!isYubiKeyAuth) return
    vm.startTimer()
    vm.yubiKeyAuthInit()
  }
})
